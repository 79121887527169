"use client";

import ReactQueryProvider from "../../app/ReactQueryProvider";
import { GoogleOAuthProvider } from "@react-oauth/google";
import ProgressBar from "../ProgressBar";
import ScreenLoader from "../ScreenLoader";
import dynamic from "next/dynamic";
import { ToastContainer } from "react-bootstrap";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const Layout = dynamic(() => import("../../components/layouts/Layout"), {
  loading: () => (
    <div style={{ width: "100%", height: "100vh" }} className="justify-content-center d-flex align-items-center">
      {/* <ScreenLoader /> */}
    </div>
  ),
  ssr: false,
});
// see: https://beta.nextjs.org/docs/rendering/server-and-client-components
export const Providers = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <GoogleOAuthProvider clientId={`${process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID}`}>
        <ReactQueryProvider>
          <ReactQueryDevtools />

          <ProgressBar />
          <Layout attribute="class">{children}</Layout>
          <ToastContainer />
        </ReactQueryProvider>
      </GoogleOAuthProvider>
    </>
  );
};
